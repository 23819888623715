import React, {
  useContext,
  useEffect,
  // useState 
} from 'react';
import {
  // Backdrop,
  //  Dialog, 
  Grid,
  //  useMediaQuery 
} from '@mui/material';
import AOS from "aos";
//============== CSS AOS ==============
import "aos/dist/aos.css";

//============== COMPONENTES ==============
import EstructuraV from '../../../estructura/EstructuraV';
import InicioV from './InicioV';
import BenefitsV from './BenefitsV';
import CarrouselV from './CarrouselV';
import CoursesV from './CoursesV';
import HowToFindV from './HowToFindV';
import ContactV from './ContactV';
import UsV from './UsV';
import { HomeContext } from '../../../Providers/HomeContext';
import FormworkV from './FormworkV';
// import { styled, useTheme } from '@mui/material/styles';

const HomeV = () => {

  //===== STATES =====
  // const [openModal, setOpenModal] = useState(false);
  // const theme = useTheme();
  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  //======= USE CONTEXT =======
  const { setActualizar } = useContext(HomeContext);

  useEffect(() => {
    //console.log('actualizar')
    setActualizar(true);
  }, [setActualizar]);


  //======= USE EFFECT =======
  useEffect(() => {
    AOS.init({
      duration: 1000,
      offset: 120,
      delay: 100,
      once: true,
    });
    AOS.refresh();
    //console.log(auth, user)
  }, []);

  //======= FUNCIONES =======
  // const handleModalPostulacion = () => {
  //   setOpenModal(!openModal);
  // }

  // const BlurredBackdrop = styled(Backdrop)({
  //   backdropFilter: 'blur(5px)', // Ajusta el valor de blur según tus necesidades
  //   backgroundColor: 'rgba(0, 0, 0, 0.5)', // Ajusta la opacidad del fondo
  // });


  //======= VISTA =======
  return (
    <EstructuraV
      render={() =>
        <Grid container direction="column">
          {/* Modal que verifica si quiere eliminar el item */}
          {/* <Dialog
            fullScreen={fullScreen}
            maxWidth={'xl'}
            open={openModal}
            onClose={handleModalPostulacion}
            aria-labelledby="Postulaciones"
            aria-describedby="Postulaciones"
            BackdropComponent={BlurredBackdrop}
            PaperProps={{
              sx: {
                backgroundColor: 'rgba(179, 179, 179, 0.5)',
                backdropFilter: 'blur(20px)',
                borderRadius: '30px',
                [theme.breakpoints.down('md')]: {
                  margin: '0px',
                  width: '100%',
                  height: '100%',
                  borderRadius: '0px',
                }
              },
            }}
          >
            <FormworkV cerrarModal={handleModalPostulacion} />
          </Dialog> */}

          <InicioV /* abrirPostulaciones={handleModalPostulacion}*/ />
          <BenefitsV />
          <CarrouselV />
          <FormworkV />
          <CoursesV />
          <UsV />
          <HowToFindV />
          <ContactV />
        </Grid>
      }
    />
  )
}

export default HomeV
