import Control from "../../../../utils/Control";

export default class HomeC extends Control {

    //Metodo que obtiene todos los datos necesarios para el home
    async getHome() {
        //console.log("Se ejecuto getImg")
        //variables declaradas que se enviarán al json
        const nameApi = 'getHome';

        //estructura de la consulta  /  consulta a la api
        const { ok, mensagge, data } = await super.requestApi({
            //json que se envía a la consulta 
            nameApi,
        })

        //respuesta a la vista
        let dataResp = {
            ok: false,
            mensagge: '',
            data: null,
        };

        // Verifico si la consulta fue satisfactoria o no
        if (ok) {
            // Armo la respuesta
            dataResp =
            {
                ok: true,
                mensagge: mensagge,
                data: data,
            };
        } else {
            console.error("Error API. ", dataResp.mensaje);
        }

        return dataResp;
    }
}