import React, { useContext, useState } from 'react';
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Grid,
  Box,
  IconButton,
  Drawer,
  Divider,
  Popover,
  Button,
  Typography,
  Avatar,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import logoAM from '../../utils/img/logo.png';
import makeStyles from '@mui/styles/makeStyles';
import { AccountCircle, Menu, AdminPanelSettings, WhatsApp, Facebook, Instagram } from '@mui/icons-material';
import { UserContext } from '../Providers/UserContext';
import logoSeba from '../../utils/img/desarrolladores/Logotipo_Personal(Sebastian_Legnazzi).png'
import logoDari from '../../utils/img/desarrolladores/Logotipo_Personal(dariana_sosa).png'

//============== ESTILOS MUI ==============
const useStyles = makeStyles((theme) => ({
  logoAM: {
    height: '2.5rem',
  },
  backGround: {
    background: theme.palette.header.main,
    color: theme.palette.common.white,
  },
  backGroundMov: {
    textAlign: 'center',
    height: '100%',
    background: theme.palette.header.main,
  },
  linkContainer: {
    paddingRight: theme.spacing(3),
  },
  linkContainerMovile: {
    paddingTop: theme.spacing(3),
  },
  auth: {
    color: theme.palette.common.white,
  },
  containerBtnAuth: {
    color: theme.palette.common.black,
    width: '100%',
    padding: theme.spacing(1, 2),
    '&:hover': {
      background: "#c4c4c4",
    },
  },
  linkAuh: {
    textDecoration: 'none',
    color: theme.palette.common.black,
  },
  txt: {
    fontSize: '0.8rem',
    color: '#b5b5b5',
  },
  iconFacebook: {
    backgroundColor: '#3b5998',
    width: '35px',
    height: '35px',
    transition: 'all 0.5s ease-in-out',
    '&:hover': {
      transform: 'scale(1.1) rotate(360deg)',
    }
  },
  iconInstagram: {
    backgroundColor: '#e4405f',
    width: '35px',
    height: '35px',
    transition: 'all 0.5s ease-in-out',
    '&:hover': {
      transform: 'scale(1.1) rotate(360deg)',
    }
  },
  iconWpp: {
    backgroundColor: '#25d366',
    width: '35px',
    height: '35px',
    transition: 'all 0.5s ease-in-out',
    '&:hover': {
      transform: 'scale(1.1) rotate(360deg)',
    }
  },
  containerMovil: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    width: '100%',
    padding: theme.spacing(2, 2),
  },
  txtHeaderDevelop: {
    fontSize: '1rem',
    color: '#b5b5b5',
  },
  txtDevelop: {
    textDecoration: 'none',
    fontSize: '0.8rem',
    color: '#b5b5b5',
    cursor: 'pointer',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      color: '#FFC800',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.6rem',
    },
  },
  logo: {
    width: '60px',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      transform: 'scale(1.1)',
    }
  },
}));


//============== APP BAR MUI ==============
const drawerWidth = 240;

//==== Constante que tiene elementos de la barra de navegacion ====
const navItems = [
  { name: 'SERVICIOS', href: 'inicio', type: 1 },
  { name: 'FLOTA', href: 'carrusel', type: 1 },
  { name: 'CURSOS', href: 'curses', type: 1 },
  { name: 'NOSOTROS', href: 'us', type: 1 },
  { name: 'CONTACTO', href: 'contact', type: 1 },
  { name: 'VIDEOS', href: 'videos', type: 2 },
];

const navItemsAdmin = [
  { name: 'Editar Curso', href: '/editCourse' },
  { name: 'Editar Imágenes', href: '/editCarrousel' },
  { name: 'Editar Equipo', href: '/editUs' },
  { name: 'Editar Videos', href: '/editVideo' },
  { name: 'Editar Flayer', href: '/editFlayer' },
];

const HeaderV = (props) => {

  //======= CONTEXTOS =======
  const { auth, cerrarsesion, admin } = useContext(UserContext);

  const navigate = useNavigate();

  //======= VARIABLE ESTILOS =======
  const classes = useStyles();

  //======= FUNCIONES =======
  const checkPath = (id, type) => {
    if (type === 1) {
      navigate('/');
      // Esperar un breve tiempo antes de desplazarse para que el componente se cargue completamente después de la redirección
      setTimeout(() => {
        scrollToSection(id)
      }, 100); // Puedes ajustar este tiempo según sea necesario
    } else {
      navigate('/'+id)
    }
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);

  //======= ACCION QUE ABRE APPBAR =======
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const [anchorElDesk, setAnchorEl] = useState(null);
  const [anchorElMov, setAnchorElMov] = useState(null);
  const [anchorElAdminDesk, setAnchorElAdminDesk] = useState(null);
  const [anchorElAdminMov, setAnchorElAdminMov] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseDesk = () => {
    setAnchorEl(null);
  };

  const handleCloseMov = () => {
    setAnchorElMov(null);
  };

  const handleClickMov = (event) => {
    setAnchorElMov(event.currentTarget);
  };

  const handleClickAdminDesk = (event) => {
    setAnchorElAdminDesk(event.currentTarget);
  };

  const handleCloseAdminDesk = () => {
    setAnchorElAdminDesk(null);
  };

  const handleClickAdminMov = (event) => {
    setAnchorElAdminMov(event.currentTarget);
  };

  const handleCloseAdminMov = () => {
    setAnchorElAdminMov(null);
  };

  const open = Boolean(anchorElDesk);
  const openMov = Boolean(anchorElMov);
  const openAdminmDesk = Boolean(anchorElAdminDesk);
  const openAdminmMov = Boolean(anchorElAdminMov);
  const id = open ? 'simple-popover' : undefined;
  const idAdmin = openAdminmDesk ? 'simple-popover-admin' : undefined;

  const whatsApp = `https://api.whatsapp.com/send?phone=5492995319130&text=Hola!%20quisiera%20consultar%20por%20los%20cursos`;
  const facebook = `https://www.facebook.com/AcademiaManejar`;
  const instagram = `https://www.instagram.com/academiamanejar/`;
  const seba = `https://www.linkedin.com/in/sebastian-legnazzi/`;
  const dari = `https://www.linkedin.com/in/dariana-sosa-3408ba200/`;

  //======= CONTENIDO APP BAR =======
  const drawer = (
    <Box className={classes.backGroundMov}>
      <Grid>
        <img src={logoAM} alt="Logo academia manejar" className={classes.logoAM} />
      </Grid>
      <Divider />
      <Grid container direction="column" spacing={2} className={classes.linkContainerMovile} >
        {navItems.map((item, index) => (
          <Grid onClick={handleDrawerToggle} item key={index}>
            <Button onClick={() => checkPath(item.href, item.type)} className={classes.link}>
              {item.name}
            </Button>
          </Grid>
        ))}
        <Grid item container justifyContent='space-evenly'>
          <Grid item>
            <IconButton aria-describedby={id} variant="contained" onClick={handleClickMov}>
              <AccountCircle className={classes.auth} />
            </IconButton>
            <Popover
              id={id}
              open={openMov}
              anchorEl={anchorElMov}
              onClose={handleCloseMov}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Grid container direction='column'>
                {!auth ? (
                  <>
                    <Grid item>
                      <Button className={classes.containerBtnAuth}>
                        <Link to="/login" className={classes.linkAuh}>
                          Ingresar
                        </Link>
                      </Button>
                    </Grid>
                    <Grid item>
                      {/* <Button className={classes.containerBtnAuth}>
                        <Link to="/register" className={classes.linkAuh}>
                          Registrarse
                        </Link>
                      </Button> */}
                    </Grid>
                  </>
                ) : (
                  <>
                    {/* <Grid item>
                      <Button className={classes.containerBtnAuth}>
                        <Link to="/profile" className={classes.linkAuh}>
                          Mi Perfil
                        </Link>
                      </Button>
                    </Grid> */}
                    <Grid item>
                      <Button onClick={cerrarsesion} className={classes.containerBtnAuth}>
                        Cerrar Sesion
                      </Button>
                    </Grid>
                  </>
                )
                }
              </Grid>
            </Popover>
          </Grid>
          {admin &&
            <Grid item>
              <IconButton aria-describedby={idAdmin} variant="contained" onClick={handleClickAdminMov}>
                <AdminPanelSettings className={classes.auth} />
              </IconButton>
              <Popover
                id={idAdmin}
                open={openAdminmMov}
                anchorEl={anchorElAdminMov}
                onClose={handleCloseAdminMov}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Grid container direction='column'>
                  {navItemsAdmin.map((item, index) => (
                    <Grid item key={index}>
                      <Button className={classes.containerBtnAuth}>
                        <Link to={item.href} className={classes.linkAuh}>
                          {item.name}
                        </Link>
                      </Button>
                    </Grid>
                  ))
                  }
                </Grid>
              </Popover>
            </Grid>
          }
          <Grid item container className={classes.containerMovil}>
            <Grid item container spacing={2}>
              <Grid item container alignContent="center" justifyContent="center" lg={4}>
                <Typography className={classes.txt}>
                  Copyright © Academia Manejar
                </Typography>
              </Grid>
              <Grid item container alignContent="center" justifyContent="center" spacing={2} >
                <Grid item>
                  <Link to={whatsApp} target="_blank">
                    <Avatar className={classes.iconWpp}>
                      <WhatsApp />
                    </Avatar>
                  </Link>
                </Grid>
                <Grid item>
                  <Link to={facebook} target="_blank">
                    <Avatar className={classes.iconFacebook}>
                      <Facebook />
                    </Avatar>
                  </Link>
                </Grid>
                <Grid item>
                  <Link to={instagram} target="_blank">
                    <Avatar className={classes.iconInstagram}>
                      <Instagram />
                    </Avatar>
                  </Link>
                </Grid>
                <Grid item container justifyContent="center" lg={4}>
                  <Grid item>
                    <Typography className={classes.txtHeaderDevelop}>
                      Desarrolladores
                    </Typography>
                  </Grid>
                  <Grid item container justifyContent="center" alignItems="center" align="center" spacing={2}>
                    <Grid item xs={6}>
                      <Link
                        to={seba}
                      >
                        <img src={logoSeba} alt="Logo Sebastian Legnazzi" className={classes.logo} />
                      </Link>
                      <Typography>
                        <Link to={seba} underline="none" color="inherit" className={classes.txtDevelop}>
                          Sebastian Legnazzi
                        </Link>
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Link
                        to={dari}
                      >
                        <img src={logoDari} alt="Logo Sebastian Legnazzi" className={classes.logo} />
                      </Link>
                      <Typography>
                        <Link to={dari} underline="none" color="inherit" className={classes.txtDevelop}>
                          Dariana Sosa
                        </Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box >
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box>
      <CssBaseline />
      <AppBar component="nav" className={classes.backGround}>
        <Toolbar>
          <Grid container justifyContent="space-between" alignContent="center">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { lg: 'none' } }}
            >
              <Menu />
            </IconButton>
            <Grid sx={{ height: { xs: '2rem' } }}>
              <img src={logoAM} alt="Logo academia manejar" className={classes.logoAM} />
            </Grid>
            <Grid xs={8} item container spacing={4} justifyContent="end" alignItems="center" className={classes.linkContainer} sx={{ display: { xs: 'none', lg: 'flex' } }}>
              {navItems.map((item, index) => (
                <Grid item key={index}>
                  <Button onClick={() => checkPath(item.href, item.type)} className={classes.link}>
                    {item.name}
                  </Button>
                </Grid>
              ))}
              <Grid item>
                <IconButton aria-describedby={id} variant="contained" onClick={handleClick}>
                  <AccountCircle className={classes.auth} />
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorElDesk}
                  onClose={handleCloseDesk}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Grid container direction='column'>
                    {!auth ? (
                      <>
                        <Grid item>
                          <Button className={classes.containerBtnAuth}>
                            <Link to="/login" className={classes.linkAuh}>
                              Ingresar
                            </Link>
                          </Button>
                        </Grid>
                        {/* <Grid item>
                          <Button className={classes.containerBtnAuth}>
                            <Link to="/register" className={classes.linkAuh}>
                              Registrarse
                            </Link>
                          </Button>
                        </Grid> */}
                      </>
                    ) : (
                      <>
                        {/*<Grid item>
                          <Button className={classes.containerBtnAuth}>
                            <Link to="/profile" className={classes.linkAuh}>
                              Mi Perfil
                            </Link>
                          </Button>
                    </Grid>*/}
                        <Grid item>
                          <Button onClick={cerrarsesion} className={classes.containerBtnAuth}>
                            Cerrar Sesion
                          </Button>
                        </Grid>
                      </>
                    )
                    }
                  </Grid>
                </Popover>
              </Grid>
              {admin &&
                <Grid item>
                  <IconButton aria-describedby={idAdmin} variant="contained" onClick={handleClickAdminDesk}>
                    <AdminPanelSettings className={classes.auth} />
                  </IconButton>
                  <Popover
                    id={idAdmin}
                    open={openAdminmDesk}
                    anchorEl={anchorElAdminDesk}
                    onClose={handleCloseAdminDesk}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    <Grid container direction='column'>

                      {navItemsAdmin.map((item, index) => (
                        <Grid item key={index}>
                          <Button className={classes.containerBtnAuth}>
                            <Link to={item.href} className={classes.linkAuh}>
                              {item.name}
                            </Link>
                          </Button>
                        </Grid>
                      ))
                      }

                    </Grid>
                  </Popover>
                </Grid>
              }
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { lg: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Toolbar />
    </Box >
  );
}

export default HeaderV
