import React, { useContext, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles';
import { Button, Grid, TextField, Typography } from '@mui/material';
import fondo from '../../../../utils/img/mapa.jpg';
import {
    AccountCircle,
    Email,
    PhoneEnabled,
} from '@mui/icons-material';
import ContactC from '../Home_C/ContactC';
import { HomeContext } from '../../../Providers/HomeContext';
//============== ESTILOS MUI ==============
const useStyles = makeStyles((theme) => ({
    container: {
        backgroundImage: `url(${fondo})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    curtine: {
        backgroundColor: 'rgba(30,30,30,0.6)',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        padding: '50px 0 50px 0',

    },
    inputContainer: {
        marginTop: '20px',
        backgroundColor: 'rgba(255,255,255,0.8)',
        borderRadius: '20px',
        boxShadow: '0px 2px 20px 3px rgba(0,0,0,0.75)',
        padding: '30px 0 30px 0',
    },
    input: {
        display: 'flex',
        alignItems: 'center',
        width: '90%',
    },
    txtMensaje: {
        width: '90%',
        paddingRight: '30px',
        [theme.breakpoints.down('md')]: {
            paddingRight: '0',
        },
    },
    txtHeader: {
        color: '#52717d',
        fontSize: '1.2rem',
        fontStyle: 'italic',
    },
    header: {
        marginBottom: '20px',
    },
    btnEnv: {
        marginTop: '20px',
        '&:hover': {
            backgroundColor: theme.palette.btn.hover,
            color: '#fff',
        },
    }
}));

const controllerContact = new ContactC();

const ContactV = () => {

    //======= CONTEXTOS =======
    const { setAlert } = useContext(HomeContext);

    //======= VARIABLE ESTILOS =======
    const classes = useStyles();

    //======= USE STATE =======
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [telefono, setTelefono] = useState('');
    const [error, setError] = useState(false);

    //Funcion que envia el email
    const sendEmail = () => {
        if (name !== '' && email !== '' && message !== '' && telefono !== '') {
            setAlert({ mensagge: 'Enviando...', type: 'info', status: true });
            const param = {
                name,
                email,
                message,
                telefono,
                arch: null,
                tipo: 'contacto',
            }
            controllerContact.sendEmail(param)
                .then(resp => {
                    if (resp.ok) {
                        setAlert({ mensagge: 'Email enviado correctamente', type: 'success', status: true });
                        setName('');
                        setEmail('');
                        setMessage('');
                        setTelefono('');
                        setError(false);
                    } else {
                        setError(true);
                        setAlert({ mensagge: 'Error al enviar email, intentelo nuevamente mas tarde.', type: 'error', status: true });
                        console.error(resp.mensagge)
                    }
                })
        } else {
            setError(true);
            setAlert({ mensagge: 'Complete todos los campos', type: 'error', status: true });
        }
    }

    return (
        <Grid container className={classes.container} id='contact'>
            <Grid item container className={classes.curtine} alignItems="center">
                <Grid item container justifyContent="center">
                    <Grid item container md={12} justifyContent="center" align="center" direction="column">
                        <Grid item>
                            <Typography variant='h3' className={classes.header} >
                                CONTACTO
                            </Typography>
                            <Typography variant='p' className={classes.txtHeader} >
                                Responderemos a la brevedad
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item container md={6} justifyContent="center" alignItems="end" className={classes.inputContainer} spacing={1}>
                        <Grid item container md={6} alignContent="center" direction="column" spacing={2}>
                            <Grid item className={classes.input}>
                                <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                <TextField
                                    id="nombre"
                                    color="letters"
                                    label="Nombre"
                                    variant="filled"
                                    fullWidth
                                    error={error}
                                    value={name}
                                    onChange={(e) => {
                                        setError(false)
                                        setName(e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item className={classes.input}>
                                <Email sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                <TextField
                                    id="email"
                                    color="letters"
                                    label="Email"
                                    variant="filled"
                                    fullWidth
                                    error={error}
                                    value={email}
                                    onChange={(e) => {
                                        setError(false)
                                        setEmail(e.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item className={classes.input}>
                                <PhoneEnabled sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                <TextField
                                    id="telefono"
                                    color="letters"
                                    label="Telefono"
                                    variant="filled"
                                    fullWidth
                                    error={error}
                                    value={telefono}
                                    onChange={(e) => {
                                        setError(false)
                                        setTelefono(e.target.value)
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid item md={6} className={classes.txtMensaje}>
                            <TextField
                                id="mensaje"
                                color="letters"
                                variant="filled"
                                label="Tu mensaje"
                                placeholder="Ingrese una descripcion"
                                multiline
                                rows={7}
                                fullWidth
                                error={error}
                                value={message}
                                onChange={(e) => {
                                    setError(false)
                                    setMessage(e.target.value)
                                }}
                            />
                        </Grid>
                        <Button
                            color='btn'
                            variant='contained'
                            className={classes.btnEnv}
                            size='large'
                            onClick={sendEmail}
                        >
                            Enviar
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default ContactV