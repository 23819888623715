const estilos = {
    palette: {
        primary: {
            main: '#FFC800', //Amarillo
        },
        secondary: {
            main: '#e3e3e3', //Verde
        },
        background: {
            main: '#FFC800', //Ni idea
        },
        btn: {
            main: '#4abc40', //Verde
            hover: '#2C7825', //Verde
        },
        header: {
            main: '#212529 !important', //Gris Oscuro
        },
        letters: {
            main: '#0a7f00', //Verde Oscuro
        }
    },
    typography: {
        fontFamily: [
            `"Montserrat",
             -apple-system, 
             BlinkMacSystemFont, 
             "Segoe UI", 
             Roboto, 
             "Helvetica Neue", 
             Arial, sans-serif, 
             "Apple Color Emoji", 
             "Segoe UI Emoji", 
             "Segoe UI Symbol", 
             "Noto Color Emoji"`
        ].join(','),
        h3: {
            fontWeight: '700',
            fontSize: '3.5rem',
            lineHeight: '4.5rem',
            marginBottom: '4rem',
            color: 'white',
        },
        h6: {
            fontSize: '1.7rem',
            fontStyle: 'italic',
            lineHeight: '2.25rem',
            color: 'white',
        }
    },

}

export default estilos;