import React, { useContext, useState } from 'react'
import makeStyles from '@mui/styles/makeStyles';
import {
    Button,
    Grid,
    Skeleton,
    // IconButton, 
    TextField,
    Typography
} from '@mui/material';
import {
    AccountCircle,
    // Close,
    CloudUpload,
    Email,
    PhoneEnabled,
} from '@mui/icons-material';
import ContactC from '../Home_C/ContactC';
import { HomeContext } from '../../../Providers/HomeContext';

const {
    REACT_APP_URL_STORAGE,
} = process.env;


//============== ESTILOS MUI ==============
const useStyles = makeStyles((theme) => ({
    container: {
        // backgroundImage: `url(${fondo})`,
        background: 'radial-gradient(circle, rgba(187,187,187,1) 0%, rgba(143,143,143,1) 100%)',
        boxShadow: 'inset 0px 0px 20px 3px rgba(0,0,0,0.5)',
        height: '800px',
        [theme.breakpoints.down('md')]: {
            height: '1200px',
        },
        [theme.breakpoints.down('sm')]: {
            height: '950px',
        },
    },
    // curtine: {
    //     height: '100%',
    //     width: '100%',
    //     justifyContent: 'center',

    // },
    inputContainer: {
        height: '350px',
        marginTop: '20px',
        backgroundColor: 'rgba(255,255,255,0.8)',
        borderRadius: '20px',
        boxShadow: '0px 2px 20px 3px rgba(0,0,0,0.75)',
        padding: '10px 0 20px 0',
    },
    input: {
        display: 'flex',
        alignItems: 'center',
        width: '90%',
    },
    txtMensaje: {
        width: '90%',
        paddingRight: '30px',
        [theme.breakpoints.down('md')]: {
            paddingRight: '0',
        },
    },
    txtHeader: {
        color: '#ffe8a3',
        fontSize: '1.2rem',
        fontStyle: 'italic',
        fontWeight: '500',
        filter: 'drop-shadow(0px 2px 3px rgba(0,0,0,0.4))',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        },
    },
    header: {
        marginBottom: '3px',
        color: '#FFFFFF',
        fontSize: '4.5rem',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.5))',
        [theme.breakpoints.down('lg')]: {
            fontSize: '3.5rem',
        },
        [theme.breakpoints.down('md')]: {
            fontSize: '3rem',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.5rem',
        },
    },
    btnEnv: {
        marginTop: '20px',
        backgroundColor: '#FFC800',
        '&:hover': {
            backgroundColor: '#e8b600',
        },
    },
    btnCv: {
        padding: '5px 60px',
        backgroundColor: '#21212170',
        color: 'white',
        border: '1px solid #e6e6e6',
        '&:hover': {
            backgroundColor: '#2121219c',
        },

    },
    imgContainer: {
        maxWidth: '680px',
        height: '600px',
        paddingBottom: '10px',
        [theme.breakpoints.down('lg')]: {
            maxWidth: '600px',
            height: '500px',
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: '600px',
            height: '600px',
        },
        [theme.breakpoints.down('sm')]: {
            height: '400px',
            margin: '0px 10px',
        }
    },
    imgFlayer: {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        borderRadius: '20px',
        boxShadow: '0px 2px 20px 3px rgba(0,0,0,0.75)',
    },
    iconCerrar: {
        fontSize: '2.2rem',
    },
    skeleton: {
        width: '300px',
        height: 600,
        [theme.breakpoints.down('md')]: {
            height: '360px',
        },
    }
}));

const controllerContact = new ContactC();

const FormworkV = ({ cerrarModal }) => {

    //======= CONTEXTOS =======
    const { setAlert,
        urlFlayer
    } = useContext(HomeContext);

    //======= VARIABLE ESTILOS =======
    const classes = useStyles();

    //======= USE STATE =======
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [telefono, setTelefono] = useState('');
    const [arch, setArch] = useState(null);
    const [error, setError] = useState(false);

    //Funcion que envia el email
    const sendEmail = () => {
        if (name !== '' && email !== '' && telefono !== '') {
            if (arch !== null) {
                setAlert({ mensagge: 'Enviando...', type: 'info', status: true });
                const param = {
                    name: name,
                    email: email,
                    arch: arch,
                    mensagge: 'Postulacion',
                    telefono: telefono,
                    tipo: 'postulacion'
                }
                controllerContact.sendEmail(param)
                    .then(resp => {
                        if (resp.ok) {
                            setAlert({ mensagge: 'Email enviado correctamente', type: 'success', status: true });
                            setName('');
                            setEmail('');
                            setTelefono('');
                            setError(false);
                        } else {
                            setError(true);
                            setAlert({ mensagge: 'Error al enviar email, intentelo nuevamente mas tarde.', type: 'error', status: true });
                            console.error(resp.mensagge)
                        }
                    })
            } else {
                setError(true);
                setAlert({ mensagge: 'Por favor, cargue su CV', type: 'error', status: true });
            }
        } else {
            setError(true);
            setAlert({ mensagge: 'Complete todos los campos', type: 'error', status: true });
        }
    }

    //Funcion que captura el cambio de CV
    const handleChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const fileType = file.type;
            const fileSize = file.size / 1024 / 1024; // Convertir a MB

            if ((fileType === 'application/pdf' ||
                fileType === 'image/jpeg' ||
                fileType === 'image/jpg' ||
                fileType === 'image/png' ||
                fileType === 'application/msword' ||
                fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document')
                && fileSize < 30) {
                setArch(file);
                setAlert({
                    mensagge: 'Archivo cargado correctamente',
                    type: 'success',
                    status: true
                });
            } else {
                setAlert({
                    mensagge: 'El archivo debe ser un PDF / JPG / PNG / DOC / DOCX y no debe superar los 30MB',
                    type: 'error',
                    status: true
                });
            }
        }
    }

    return (
        <Grid container className={classes.container} id='formwork'>
            <Grid item container className={classes.curtine} alignItems="center">
                <Grid item container justifyContent="center">
                    <Grid item container md={12} justifyContent="center" align="center" direction="column">
                        {/* <Grid align='end'>
                            <IconButton
                                aria-label="close"
                                onClick={cerrarModal}
                            >
                                <Close className={classes.iconCerrar} />
                            </IconButton>
                        </Grid> */}
                        <Grid item marginBottom={2}>
                            <Typography variant='h3' className={classes.header} >
                                SUMATE A NUESTRO EQUIPO!
                            </Typography>
                            <Typography variant='p' className={classes.txtHeader} >
                                Forma parte de nuestro staff
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justifyContent='space-evenly' spacing={2}>
                        <Grid item className={classes.imgContainer}>
                            {urlFlayer ? (
                                <img src={REACT_APP_URL_STORAGE + urlFlayer} alt="Flyer Sumate a nuestro equipo" className={classes.imgFlayer} />
                            ) : (
                                <Skeleton className={classes.skeleton}>
                                </Skeleton>
                            )

                            }
                        </Grid>
                        <Grid item container direction='column' justifyContent='center' md={5}>
                            <Grid item container justifyContent="center" alignItems="end" className={classes.inputContainer} spacing={1}>
                                <Grid item container alignContent="center" direction="column" spacing={1}>
                                    <Grid item className={classes.input}>
                                        <AccountCircle sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                        <TextField
                                            id="nombre"
                                            color="letters"
                                            label="Nombre"
                                            variant="filled"
                                            fullWidth
                                            error={error}
                                            value={name}
                                            onChange={(e) => {
                                                setError(false)
                                                setName(e.target.value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item className={classes.input}>
                                        <Email sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                        <TextField
                                            id="email"
                                            color="letters"
                                            label="Email"
                                            variant="filled"
                                            fullWidth
                                            error={error}
                                            value={email}
                                            onChange={(e) => {
                                                setError(false)
                                                setEmail(e.target.value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item className={classes.input}>
                                        <PhoneEnabled sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                        <TextField
                                            id="telefono"
                                            color="letters"
                                            label="Telefono"
                                            variant="filled"
                                            fullWidth
                                            error={error}
                                            value={telefono}
                                            onChange={(e) => {
                                                setError(false)
                                                setTelefono(e.target.value)
                                            }}
                                        />
                                    </Grid>
                                    <Grid item className={classes.input}>
                                        <CloudUpload sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                                        <Button
                                            component="label"
                                            role={undefined}
                                            variant="contained"
                                            tabIndex={-1}
                                            className={classes.btnCv}
                                        >
                                            Cargar CV
                                            <input
                                                hidden
                                                onChange={handleChange}
                                                type="file"
                                            />
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Button
                                    color='btn'
                                    variant='contained'
                                    className={classes.btnEnv}
                                    size='large'
                                    onClick={sendEmail}
                                >
                                    Enviar postulación
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default FormworkV