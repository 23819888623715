import React, { createContext, useEffect, useState } from 'react';
import HomeC from '../general/Home/Home_C/HomeC';
import packageJson from "../../../package.json";

// Crear un contexto
const HomeContext = createContext();

const controllerHome = new HomeC();

// Crear el componente Provider
const HomeProvider = ({ children }) => {

    // Aquí puedes definir el estado o los datos que deseas proporcionar en el contexto.
    const [carrousel, setCarrousel] = useState(null);
    const [viewCount, setViewCount] = useState(null);
    const [us, setUs] = useState(null);
    const [courses, setCourses] = useState(null);
    const [urlInstructive, setUrlInstructive] = useState(null);
    const [video, setVideo] = useState(null);
    const [urlFlayer, setUrlFlayer] = useState(null);
    const [alert, setAlert] = useState({ mensagge: '', type: 'error', status: false });
    const [openModal, setOpenModal] = useState({ mensagge: '', title: '', subTitle: '', open: false, loading: false });
    const [actualizar, setActualizar] = useState(false);

    //Funcion que cierra el alert
    const handleCloseAlert = () => {
        setAlert({ mensagge: '', type: alert.type, status: false });
    }

    useEffect(() => {
        //Busca todos los datos para el home
        if (localStorage.getItem('version') !== packageJson.version) {
            localStorage.setItem('version', packageJson.version);
            window.location.reload(true);
        } else {
            if ((!courses && !carrousel && !us && !urlInstructive && !video) || actualizar) {
                controllerHome.getHome()
                    .then((data) => {
                        if (data.ok) {
                            setCourses(data.data.arrayCurse);
                            setCarrousel(data.data.imgCarrosuel);
                            setUs(data.data.imgUs);
                            setUrlInstructive(data.data.urlInstructive);
                            setVideo(data.data.arrayVideo);
                            setViewCount(data.data.viewCount);
                            setUrlFlayer(data.data.urlFlayer);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }
        setActualizar(false);
    }, [actualizar, carrousel, courses, us, urlInstructive, video]);

    return (
        <HomeContext.Provider value={{
            us,
            setUs,
            carrousel,
            setCarrousel,
            courses,
            setCourses,
            setUrlInstructive,
            urlInstructive,
            handleCloseAlert,
            alert,
            setAlert,
            openModal,
            setOpenModal,
            video,
            setVideo,
            setActualizar,
            viewCount,
            urlFlayer,
            setUrlFlayer,
        }}>
            {children}
        </HomeContext.Provider>
    );
};

export { HomeContext, HomeProvider };