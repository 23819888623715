import React, { useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { InfoOutlined } from '@mui/icons-material';
import {
    Grid,
    List, ListItem,
    Card,
    CardContent,
    Typography,
    CardMedia,
    Paper,
    Skeleton
} from '@mui/material';
import { HomeContext } from '../../../Providers/HomeContext';

const {
    REACT_APP_URL_STORAGE,
} = process.env;


//============== ESTILOS MUI ==============
const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.palette.background.main,
        padding: '30px 10px 30px 10px'
    },
    iconInfo: {
        fontSize: '120%',
        color: theme.palette.letters.main,
    },
    title: {
        fontWeight: 700,
        fontSize: '30px',
    },
    subtitle: {
        fontWeight: 700,
        fontSize: '20px',
    },
    price: {
        fontWeight: 700,
        color: theme.palette.letters.main,
    },
    cardContainer: {
        borderRadius: '20px',
        width: '100%',
        height: '100%',
    },
    card: {
        borderRadius: '20px',
        height: '100%',
    },
    listText: {
        backgroundColor: theme.palette.secondary.main,
        borderRadius: '10px',
        padding: '5px 20px 5px 20px',
        marginLeft: theme.spacing(1),
        fontSize: '15px',
    },

    listItem: {
        [theme.breakpoints.down('md')]: {
            flexBasis: '90%',
        },
        aligncourses: 'center',
        flexBasis: '50%',
    },

    cardMedia: {
        height: 140,
    },
    list: {
        width: '100%',
        maxWidth: 500,
        bgcolor: 'background.paper',
        display: 'flex',
        flexWrap: 'wrap',
    },
    skeleton: {
        width: 400,
        height: 500,
        [theme.breakpoints.down('md')]: {
            width: 250,
            height: 300,
        },
    }
}));

const CoursesV = () => {

    //======= USE CONTEXT =======
    const { courses } = useContext(HomeContext);

    //======= VARIABLE ESTILOS =======
    const classes = useStyles();

    //======= VISTA =======
    return (
        <Grid item container className={classes.container} id='curses' align="center" alignContent="center">
            <Grid item container spacing={8} justifyContent="center">
                {courses ? (
                    courses.map((item, index) => (
                        <Grid item key={index} xs={11} md={5} lg={4}>
                            <Paper className={classes.cardContainer} elevation={12}>
                                <Card className={classes.card} >
                                    <CardMedia
                                        sx={{ height: 140 }}
                                        image={REACT_APP_URL_STORAGE + item.urlimg}
                                        alt={REACT_APP_URL_STORAGE + item.urlimg}
                                    />
                                    <CardContent>
                                        <Grid >
                                            <Typography className={classes.title} >
                                                {item.title}
                                            </Typography>
                                            <Typography variant="h6" className={classes.price}>$ {item.price}</Typography>
                                        </Grid>
                                        <List className={classes.list}>
                                            {item.features.map((feature, idx) => (
                                                <ListItem className={classes.listItem} key={idx}>
                                                    <InfoOutlined className={classes.iconInfo} />
                                                    <Typography className={classes.listText}>
                                                        {feature.label}
                                                    </Typography>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </CardContent>
                                </Card></Paper>
                        </Grid>
                    ))
                ) : (
                    <Grid item container spacing={10} justifyContent="center" align="center">
                        <Grid item>
                            <Skeleton variant="rectangular" animation="wave" className={classes.skeleton} />
                        </Grid>
                        <Grid item>
                            <Skeleton variant="rectangular" animation="wave" className={classes.skeleton} />
                        </Grid>
                    </Grid>
                )
                }
            </Grid>
        </Grid>
    )
}

export default CoursesV 