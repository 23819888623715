//========== FUNCIONES ==========
import React from 'react';
import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { UserProvider } from './components/Providers/UserContext';
import { HomeProvider } from './components/Providers/HomeContext';
import estilos from './utils/estilosMui';
import RutasDinamicas from './components/estructura/RutasDinamicas';


function App() {

  const theme = createTheme(estilos);



  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <HomeProvider>
          <UserProvider>
            <RutasDinamicas />
          </UserProvider>
        </HomeProvider>
      </ThemeProvider>
    </StyledEngineProvider>

  );
}

export default App;
