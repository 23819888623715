import Control from "../../../../utils/Control";

export default class ResetPasswordC extends Control {

    //Metodo que verifica el token
    async verifyToken(token) {
        //Variables declaradas que se enviarán al json
        const nameApi = 'tokenPass';
        //Estructura de la consulta  /  Consulta a la api
        const { ok, mensagge, data } = await super.requestApi({
            //JSON que se envía a la consulta 
            nameApi,
            jsonData: {
                token,
            },
        })

        //Respuesta a la vista
        let dataResp = {
            ok: false,
            mensagge: '',
            data: null,
        };

        //Verifico si la consulta fue satisfactoria o no
        if (ok) {
            //Armo la respuesta
            dataResp =
            {
                ok: true,
                mensagge: mensagge,
                data: data,
            };
        } else {
            dataResp.mensagge = mensagge;
        }

        return dataResp;
    }

    //Metodo que modifica la pass
    async modifyPass(pass, idUser, token) {
        //Variables declaradas que se enviarán al json
        const nameApi = 'modifyPass';
        const passEncrypt = super.encryptPass(pass)
        const { ok, mensagge, data } = await super.requestApi({
            //JSON que se envía a la consulta 
            nameApi,
            jsonData: {
                password: passEncrypt,
                idUser: idUser,
                idToken: token,
            },
        })

        //Respuesta a la vista
        let dataResp = {
            ok: false,
            mensagge: '',
            data: null,
        };

        //Verifico si la consulta fue satisfactoria o no
        if (ok) {
            //Armo la respuesta
            dataResp =
            {
                ok: true,
                mensagge: mensagge,
                data: data,
            };
        } else {
            dataResp.mensagge = mensagge;
        }

        return dataResp;
    }

    //Metodo que envia el email
    async sendEmail(email) {
        //Variables declaradas que se enviarán al json
        const nameApi = 'forgetPass';
        const { ok, mensagge, data } = await super.requestApi({
            //JSON que se envía a la consulta 
            nameApi,
            jsonData: {
                email,
            },
        })

        //Respuesta a la vista
        let dataResp = {
            ok: false,
            mensagge: '',
            data: null,
        };

        //Verifico si la consulta fue satisfactoria o no
        if (ok) {
            //Armo la respuesta
            dataResp =
            {
                ok: true,
                mensagge: mensagge,
                data: data,
            };
        } else {
            dataResp.mensagge = mensagge;
        }

        return dataResp;
    }
}