import React, { createContext, useContext, useEffect, useState } from 'react';
import LoginC from '../general/Login/Login_C/LoginC';
import { HomeContext } from './HomeContext';

// Crear un contexto
const UserContext = createContext();
const controllerLoginC = new LoginC();

// Crear el componente Provider
const UserProvider = ({ children }) => {

    const { setOpenModal, setAlert } = useContext(HomeContext);

    // Aquí puedes definir el estado o los datos que deseas proporcionar en el contexto.
    const [auth, setAuth] = useState(false);
    const [admin, setAdmin] = useState(false);
    const [user, setUser] = useState(null);

    //FormPersonalV
    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');

    //FormUserV
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');

    const [error, setError] = useState(false);


    const cerrarsesion = () => {
        setAuth(false);
        setUser(null);
        setAdmin(false);
        localStorage.removeItem("token");
        localStorage.removeItem("rememberMe");
    }

    useEffect(() => {
        //Verificamos si ya esta ingresado el usuario
        const token = localStorage.getItem("rememberMe");
        if (token) {
            setOpenModal({ mensagge: 'Cargando datos...', title: 'Iniciando sesión', open: true, loading: true })
            controllerLoginC.loginAutomatic(token)
                .then((res) => {
                    setOpenModal({ open: false })
                    if (res.ok) {
                        setUser(res.data);
                        setAuth(true);
                    }
                }).catch((err) => {
                    setOpenModal({ open: false })
                    setAlert({ mensagge: 'Error con el servidor, por favor intentelo mas tarde', type: 'error', status: true })
                    console.log(err);
                });
        }
    }, [setAlert, setOpenModal]);

    return (
        <UserContext.Provider value={{
            auth,
            setAuth,
            user,
            setUser,
            cerrarsesion,
            name,
            setName,
            lastname,
            setLastname,
            email,
            setEmail,
            admin,
            setAdmin,
            username,
            setUsername,
            password,
            setPassword,
            passwordRepeat,
            setPasswordRepeat,
            error,
            setError
        }}>
            {children}
        </UserContext.Provider>
    );
};

export { UserContext, UserProvider };