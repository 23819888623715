import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import EstructuraV from '../estructura/EstructuraV';
import makeStyles from '@mui/styles/makeStyles';
import { Link, useNavigate } from 'react-router-dom';

//============== ESTILOS MUI ==============
const useStyles = makeStyles((theme) => ({
  txt: {
    color: 'red',
    marginTop: '30px',
  },
}));

const Error404V = () => {
  //======= VARIABLE ESTILOS =======
  const classes = useStyles();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/')
    }
  }, [navigate]);

  setTimeout(() => {
    setLoading(false);
  }, 5000);

  //======= VISTA =======
  return (
    <EstructuraV
      render={() => (
        <Grid container justifyContent='center' alignItems='center' alignContent='center' direction='column'>
          {loading ? (
            <Grid item>
              <Typography variant='h6' className={classes.txt}>Cargando pagina... <CircularProgress className={classes.spinnerEsperando} /></Typography>
            </Grid>
          ) : (
            <>
              <Grid item>
                <Typography variant='h6' className={classes.txt}>Pagina No encontrada!</Typography>
              </Grid>
              <Grid item>
                <Button>
                  <Link to='/' style={{textDecoration:'none'}}>
                    Volver al inicio
                  </Link>
                </Button>
              </Grid>
            </>
          )
          }
        </Grid>
      )}
    />
  )
}

export default Error404V
