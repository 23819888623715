import React from 'react';
import {
    Grid,
} from '@mui/material';
// import makeStyles from '@mui/styles/makeStyles';
import EstructuraV from '../../../estructura/EstructuraV';


//============== ESTILOS MUI ==============
// const useStyles = makeStyles((theme) => ({
// }));

const ProfileV = () => {

    //======= VARIABLE ESTILOS =======
    //const classes = useStyles();


    //======= VISTA =======
    return (
        <EstructuraV
            render={() => (
                <Grid item container id='login' align="center" alignContent="center">
                    mi perfil
                </Grid>
            )}
        />
    )
}

export default ProfileV