import Control from "../../../../utils/Control";

export default class VideosC extends Control {

    //Metodo que obtiene un video
    async getVideo() {
        const nameApi = 'getVideo';

        //Estructura de la consulta  /  Consulta a la api
        const { ok, mensagge, data } = await super.requestApi({
            //JSON que se envía a la consulta 
            nameApi,
        })

        //Respuesta a la vista
        let dataResp = {
            ok: false,
            mensagge: '',
            data: null,
        };

        //Verifico si la consulta fue satisfactoria o no
        if (ok) {
            //Armo la respuesta
            dataResp =
            {
                ok: true,
                mensagge: mensagge,
                data: data,
            };
        } else {
            dataResp.mensagge = mensagge;
        }

        return dataResp;
    }

}