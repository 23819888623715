import Control from "../../../../utils/Control";

export default class ContactC extends Control {

    //Metodo que obtiene las imagenes del carrousel de la base de datos
    async sendEmail(param) {
        //console.log("sendEmail")
        //Variables declaradas que se enviarán al json
        const nameApi = 'sendEmail';
        const formData = new FormData();
        const { name, email, message, telefono, arch, tipo } = param;
        formData.append('name', name);
        formData.append('email', email);
        formData.append('message', message);
        formData.append('telefono', telefono);
        formData.append('arch', arch);
        formData.append('tipo', tipo);

        //Estructura de la consulta  /  Consulta a la api
        const { ok, mensagge, data } = await super.requestApiForm({
            //JSON que se envía a la consulta 
            nameApi,
            formData
        })
        console.log(ok, mensagge, data)

        //Respuesta a la vista
        let dataResp = {
            ok: false,
            mensagge: '',
            data: null,
        };

        //Verifico si la consulta fue satisfactoria o no
        if (ok) {
            //Armo la respuesta
            dataResp =
            {
                ok: true,
                mensagge: mensagge,
                data: data,
            };
        } else {
            console.error("Error API. ", dataResp.mensaje);
        }

        return dataResp;
    }
}