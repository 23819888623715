import React, { useContext, useEffect, useState, Suspense, lazy, useCallback } from 'react'
import { Route, Routes } from "react-router-dom";
import { UserContext } from '../Providers/UserContext';

/*================    Providers   ================*/
import { Grid, Skeleton } from '@mui/material';

/*================    COMPONENTES GENERALES   ================*/
import HomeV from '../general/Home/Home_V/HomeV';
import LoginV from '../general/Login/Login_V/LoginV';
import EstructuraV from './EstructuraV';
// import RegisterV from '../general/Register/Register_V/RegisterV';
import Error404V from '../general/Error404V';
import ResetPasswordV from '../general/Login/Login_V/ResetPasswordV';
import RequestEmailV from '../general/Login/Login_V/RequestEmailV';
import ProfileV from '../general/Profile/Profile_V/ProfileV';
import VideosV from '../general/Videos/Videos_V/VideosV';

/*================    COMPONENTES ADMIN   ================*/
const EditCourseV = lazy(() => import("../administrador/EditCourse/EditCourse_V/EditCourseV"));
const EditCarrouselV = lazy(() => import("../administrador/EditCarrousel/EditCarrousel_V/EditCarrouselV"));
const EditUsV = lazy(() => import("../administrador/EditUs/EditUs_V/EditUsV"));
const EditVideosV = lazy(() => import("../administrador/EditVideo/EditVideo_V/EditVideosV"));
const EditFlayerV = lazy(() => import("../administrador/EditFlayer/EditFlayer_V/EditFlayerV"));


const RutasDinamicas = () => {
    //Traigo variable con los datos del usuario logeado del provider
    const { auth, user, setAdmin } = useContext(UserContext);

    //Creo state para almacenar los menus
    const [arrayMenus, setArrayMenus] = useState([]);

    //Funcion que carga todos los menus segun el rol
    const cargarMenuRol = useCallback(() => {
        if (user != null && auth) {
            //console.log(user)
            let arrayRoles = user.roleIds.split("#");
            let arrayDatosMenus
            //console.log(arrayRoles)
            //Me fijo de todos los roles cuales tiene para cargarle los menus
            arrayDatosMenus = arrayRoles.map((rol) => {
                let arrayMenu = []
                // Segun el rol cargo las distintas rutas posibles para visualizar
                switch (rol) {

                    //========= GERENTE =========
                    case "3":
                        setAdmin(true);
                        arrayMenu = [
                            { componente: <EditCourseV />, url: "/editCourse" },
                            { componente: <EditCarrouselV />, url: "/editCarrousel" },
                            { componente: <EditUsV />, url: "/editUs" },
                            { componente: <EditVideosV />, url: "/editVideo" },
                            { componente: <EditFlayerV />, url: "/editFlayer" },
                        ];
                        break;

                    default: setArrayMenus([]);
                }
                return arrayMenu
            })
            //Elimino arrays vacios
            const arrayFiltrado = arrayDatosMenus.filter((subArray) => subArray.length > 0);
            setArrayMenus(arrayFiltrado);
            //console.log(arrayFiltrado)
        }
    }, [user ,auth, setAdmin]);

    // Cargo la funcion cuando se logea
    useEffect(() => {
        if (auth) {
            cargarMenuRol();
        }
    }, [user, cargarMenuRol, auth])

    //Componente que se utiliza cuando se esta cargando la pagina
    const Loading = () => {
        return (
            <EstructuraV
                render={() => (
                    // <Typography variant="h6">Cargando... <CircularProgress /></Typography>
                    <Grid container>
                        <Skeleton
                            height={200}
                            width='80%'
                            style={{ margin: 'auto' }}
                        />
                        <Skeleton
                            height={200}
                            width='80%'
                            style={{ margin: 'auto' }}
                        />
                        <Skeleton
                            height={200}
                            width='80%'
                            style={{ margin: 'auto' }}
                        />
                    </Grid>
                )} />
        )
    }

    return (
        <Suspense fallback={<Loading />}>
            <Routes>

                {/* Rutas declaradas para la vista */}
                <Route
                    path="/"
                    exact
                    element={<HomeV />} />

                <Route
                    path="/login"
                    exact
                    element={<LoginV />} />

                <Route
                    path="/reset-password/:token"
                    element={<ResetPasswordV />} />

                <Route
                    path="/request_email"
                    element={<RequestEmailV />} />

                {/* <Route
                    path="/register"
                    exact
                    element={<RegisterV />} /> */}

                <Route
                    path="/profile"
                    exact
                    element={<ProfileV />} />

                <Route
                    path="/videos"
                    exact
                    element={<VideosV />} />

                <Route
                    path="*"
                    element={<Error404V />} />

                {/* Se crean las rutas dependiendo los roles que se obtienen */}
                {arrayMenus.length > 0 && auth &&
                    arrayMenus.map((arrayDatosMenu) => (
                        arrayDatosMenu.map((datosMenu, index) => (
                            (
                                <Route
                                    key={index}
                                    path={datosMenu.url}
                                    element={datosMenu.componente} />
                            )
                        ))
                    ))
                }
            </Routes>
        </Suspense>
    )
}

export default RutasDinamicas